import { dispatch, useSelector } from "../../store";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { Stack } from "@mui/system";
import {
  Input,
  List,
  ListItemButton,
  Popover,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { linkSX } from "./Breadcrumb.helpers";

import {
  getDashboards,
  setSelectedDashboard,
} from "../../store/slices/analytics";

import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useLocusRouter } from "../../hooks/useLocusRouter";

/** @interface Dashboard
 *
 * export interface Dashboard {
 *   isDefault: boolean;
 *   sites?: (null)[] | null;
 *   groupAccess?: (null)[] | null;
 *   _id: string;
 *   name: string;
 *   description: string;
 *   reportId: string;
 *   type: string;
 *   category: string;
 *   id: string;
 * }
 **/

export const DashboardPopover = ({ kioskMode }) => {
  const theme = useTheme();
  const { selectedDashboard, availableDashboards } = useSelector(
    (state) => state.analytics,
  );
  const { selectedSite } = useSelector((state) => state.customer);
  const [filteredDashboardsList, setFilteredDashboardsList] = useState([]);

  const pathSegments = useLocation().pathname.split("/");
  const analyticsSection = pathSegments[3];
  const analyticsCategory = pathSegments[4];
  const { searchParams, updateSearchParams } = useLocusRouter();
  const dashboardIdParam = searchParams.get("dashboard");

  const setDashboard = (dashboard) => {
    if (dashboard?.id) {
      dispatch(setSelectedDashboard(dashboard));
      updateSearchParams({ dashboard: dashboard.id });
    }
  };

  useEffect(() => {
    if (analyticsSection === "dashboards" || analyticsSection === "reports") {
      dispatch(
        getDashboards(analyticsCategory, analyticsSection, selectedSite),
      );
    }
  }, [analyticsCategory, analyticsSection, selectedSite]);

  useEffect(() => {
    setFilteredDashboardsList(availableDashboards);
    if (
      availableDashboards.some(
        (dashboard) => dashboard.reportId === selectedDashboard?.reportId,
      )
    ) {
      return;
    }

    if (availableDashboards.length > 0) {
      const dashboardToSelectFromQueryParam = availableDashboards.find(
        (dashboard) => dashboard.id === dashboardIdParam,
      );
      setDashboard(
        dashboardToSelectFromQueryParam
          ? dashboardToSelectFromQueryParam
          : availableDashboards[0],
      );
    } else {
      setDashboard(null);
    }
  }, [availableDashboards]);

  const handleSearchDashboards = function (event) {
    const _searchValue = event?.target?.value;
    const _filteredList = availableDashboards?.filter((dashboard) =>
      dashboard.name.toLowerCase().includes(_searchValue?.toLowerCase() || ""),
    );
    setFilteredDashboardsList(_filteredList);
  };

  const handleDashboardSelect = function (dashboard, popupState) {
    setDashboard(dashboard);
    handlePopoverClose(popupState);
  };

  const handlePopoverClose = (popupState) => {
    setTimeout(() => {
      setFilteredDashboardsList(availableDashboards);
    }, 100);
    popupState.close();
  };

  return (
    <>
      {kioskMode ? (
        <Typography color="inherit" variant="body2" sx={linkSX(theme)}>
          {selectedDashboard?.name}
        </Typography>
      ) : (
        <PopupState
          variant="popover"
          popupId="simple-popover"
          disableAutoFocus={true}
        >
          {(popupState) => (
            <Stack
              sx={{ cursor: availableDashboards?.length > 0 ? "pointer" : "" }}
              className="guided-tour-dashboard-report-selection"
            >
              <Typography sx={linkSX(theme)} {...bindTrigger(popupState)}>
                {selectedDashboard ? selectedDashboard?.name : "None Available"}
                {availableDashboards?.length > 0 && <ArrowDropDownIcon />}
              </Typography>
              {availableDashboards?.length > 0 && (
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClose={() => handlePopoverClose(popupState)}
                >
                  <Stack sx={{ width: 250, p: 1 }}>
                    <Input
                      disableUnderline
                      sx={{
                        border: `1px solid ${theme.palette.grey[200]}`,
                        background: theme.palette.grey[100],
                        height: 35,
                        textIndent: 7,
                        borderRadius: 1,
                      }}
                      placeholder="Search"
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      onChange={handleSearchDashboards}
                    />
                  </Stack>
                  <Stack sx={{ maxHeight: 400 }}>
                    {
                      <List component="ul">
                        {filteredDashboardsList?.map((dashboard) => (
                          <ListItemButton
                            key={dashboard?.name}
                            sx={{ fontSize: 14 }}
                            selected={selectedDashboard?.id === dashboard?.id}
                            onClick={() =>
                              handleDashboardSelect(dashboard, popupState)
                            }
                          >
                            {dashboard?.name}
                          </ListItemButton>
                        ))}
                      </List>
                    }
                  </Stack>
                </Popover>
              )}
            </Stack>
          )}
        </PopupState>
      )}
    </>
  );
};

DashboardPopover.propTypes = {
  kioskMode: PropTypes.bool,
};
