import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusDropLocationIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="currentColor"
      >
        <rect width="50" height="50" rx="8" fill="#0078CF" />
        <path
          d="M37.0533 13.8147L35.1884 15.6805L37.0398 17.5598C37.1649 17.685 37.2641 17.8336 37.3318 17.9972C37.3996 18.1607 37.4344 18.336 37.4344 18.513C37.4344 18.69 37.3996 18.8653 37.3318 19.0289C37.2641 19.1924 37.1649 19.341 37.0398 19.4662C36.9147 19.5914 36.7661 19.6907 36.6027 19.7584C36.4392 19.8261 36.264 19.861 36.087 19.861C35.9101 19.861 35.7349 19.8261 35.5714 19.7584C35.408 19.6907 35.2594 19.5914 35.1343 19.4662L33.2694 17.6004L31.4045 19.4391C31.2794 19.5643 31.1308 19.6636 30.9673 19.7314C30.8039 19.7991 30.6287 19.834 30.4517 19.834C30.0944 19.834 29.7517 19.6919 29.499 19.4391C29.3739 19.314 29.2746 19.1654 29.2069 19.0018C29.1392 18.8383 29.1044 18.663 29.1044 18.486C29.1044 18.3089 29.1392 18.1337 29.2069 17.9701C29.2746 17.8066 29.3739 17.658 29.499 17.5328L31.3639 15.667L29.499 13.8012C29.2463 13.5484 29.1044 13.2055 29.1044 12.848C29.1044 12.4905 29.2463 12.1476 29.499 11.8948C29.7517 11.642 30.0944 11.5 30.4517 11.5C30.8091 11.5 31.1518 11.642 31.4045 11.8948L33.2694 13.7606L35.1478 11.9083C35.4005 11.6555 35.7432 11.5135 36.1006 11.5135C36.4579 11.5135 36.8006 11.6555 37.0533 11.9083C37.306 12.1611 37.4479 12.504 37.4479 12.8615C37.4479 13.219 37.306 13.5619 37.0533 13.8147ZM23.8097 12.3004C24.3369 12.3004 24.8583 12.338 25.3706 12.4116C25.2334 13.0287 25.1611 13.6701 25.1611 14.3285C25.1611 19.1821 29.0938 23.1167 33.9451 23.1167C34.1712 23.1167 34.3953 23.1081 34.6171 23.0913C34.6196 23.1893 34.6208 23.2879 34.6208 23.387C34.6208 27.6865 31.3099 32.7431 24.7151 38.5703C24.2016 39.0165 23.4313 39.0165 22.9178 38.5703C16.3094 32.7431 12.9985 27.6865 12.9985 23.387C12.9985 16.6539 18.1338 12.3004 23.8097 12.3004ZM23.8097 25.8207C22.3231 25.8207 21.1069 24.6039 21.1069 23.1166C21.1069 21.6294 22.3231 20.4126 23.8097 20.4126C25.2962 20.4126 26.5125 21.6294 26.5125 23.1166C26.5125 24.6039 25.2962 25.8207 23.8097 25.8207Z"
          fill="#F9F9F9"
        />
      </svg>
    </SvgIcon>
  );
};
