import { storage } from "./utils/helpers/storage";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

// eslint-disable-next-line no-unused-vars
let activeServiceWorkerPoll;

export const serviceWorkerInit = (notifyUser) => {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      let swURL = isLocalhost
        ? "http://localhost:3000"
        : process.env.REACT_APP_UI_URL;

      if (isLocalhost) {
        navigator.serviceWorker
          .getRegistrations()
          .then((registrations) => {
            // Unregister any found service workers and do not register
            registrations.forEach((registration) => {
              registration.unregister();
            });
          })
          .catch((error) => {
            console.error("Error during service worker unregistration:", error);
          });
        return;
      }

      navigator.serviceWorker
        .register(`${swURL}/service-worker.js`, {
          scope: "./",
          updateViaCache: "none",
        })
        .then(async (registration) => {
          if (registration.active) {
            activeServiceWorkerPoll = setInterval(function () {
              registration.active.postMessage({
                type: "SET_CURRENT_APP_VERSION",
                appVersion: storage.getLocusAppVersion(),
              });
              registration.active.postMessage({
                type: "POLL_FOR_APP_UPDATES",
              });
            }, 60000);
          }
        })
        .catch((error) => {
          console.log("Service Worker registration failed:", error);
        });
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data && event.data.type === "NEW_APP_VERSION_AVAILABLE") {
          console.log(`${event.data.payload}: v${event.data.appVersion}`);
          storage.setLocusAppVersion(event.data.appVersion);
          if (storage.getKiosk()) {
            console.log(`Kiosk Service Worker reloading`);
            window.location.reload(true);
          } else {
            notifyUser(true);
          }
        }
      });
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        // console.log("The controller of current browsing context has changed.");
      });
    });
  }
};

async function manuallyCheckForUpdates() {
  try {
    const registration = await navigator.serviceWorker.ready;
    registration.active.postMessage({ type: "checkForUpdates" });
  } catch (error) {
    console.error("Service worker not ready:", error);
  }
}
