import Cookies from "js-cookie";

const COOKIES = {
  ACTIVE_USER: "activeUser",
  ACCESS_TOKEN: "accessToken",
  CLIENT: "client",
  CLIENT_ID: "clientId",
  DOMAIN: "domain",
  KIOSK: "kiosk",
  KIOSK_ID: "kiosk_id",
  KIOSK_NAME: "kiosk_name",
  LOCUS: "locus",
  LOCUS_CONFIG: "locus_config",
  LOCUS_APP_VERSION: "locus_app_version",
  SITE: "site",
  REFRESH_TOKEN: "refreshToken",
  REGION: "region",
  USER_REGION: "userRegion",
  REVOCATION_ENDPOINT: "revocation_endpoint",
  SSO_IDP_CLIENT: "ssoIdpClient",
  SSO_IDP_URL: "ssoIdpUrl",
  SSO_USER: "ssoUser",
  USERINFO_ENDPOINT: "userinfo_endpoint",
  USERS: "users",
  VIEW_STATE: "viewState",
  SELECTED_DASHBOARD: "selectedDashboard",
};

const PERSISTENT_COOKIES = [
  COOKIES.KIOSK,
  COOKIES.KIOSK_ID,
  COOKIES.LOCUS_APP_VERSION,
];

const setterConfig = {
  path: "/",
  sameSite: "Strict",
  secure: true,
};
export const storage = {
  log: () => {
    // eslint-disable-next-line array-callback-return
    Object.keys(COOKIES).map((name) => {
      let _cookie = Cookies.get(COOKIES[name]);
      if (!!_cookie) {
        console.log(`[${COOKIES[name]}]: ${_cookie}`);
      }
    });
  },
  getActiveUser: () => {
    return Cookies.get(COOKIES.ACTIVE_USER);
  },
  setActiveUser: (user) => {
    Cookies.set(COOKIES.ACTIVE_USER, user, setterConfig);
  },
  getSite: () => {
    return Cookies.get(COOKIES.SITE);
  },
  setSite: (site) => {
    Cookies.set(COOKIES.SITE, site, setterConfig);
  },
  getClientId: () => {
    return Cookies.get(COOKIES.CLIENT_ID);
  },
  setClientId: (id) => {
    Cookies.set(COOKIES.CLIENT_ID, id, setterConfig);
  },
  getAccessToken: () => {
    return Cookies.get(COOKIES.ACCESS_TOKEN);
  },
  getRefreshToken: () => {
    return Cookies.get(COOKIES.REFRESH_TOKEN);
  },
  getDomain: () => {
    return Cookies.get(COOKIES.DOMAIN);
  },
  setDomain: (domain) => {
    Cookies.set(COOKIES.DOMAIN, domain, setterConfig);
  },
  getRegion: () => {
    return Cookies.get(COOKIES.REGION);
  },
  setRegion: (region) => {
    Cookies.set(COOKIES.REGION, region, setterConfig);
  },
  getUserRegion: () => {
    return Cookies.get(COOKIES.USER_REGION);
  },
  setUserRegion: (region) => {
    Cookies.set(COOKIES.USER_REGION, region, setterConfig);
  },
  getKiosk: () => {
    return Cookies.get(COOKIES.KIOSK);
  },
  setKiosk: (url) => {
    if (url) {
      Cookies.set(COOKIES.KIOSK, url, { ...setterConfig, expires: 30 });
    } else {
      Cookies.remove(COOKIES.KIOSK, { path: "/" });
    }
  },
  setKioskName: (data) => {
    if (data) {
      Cookies.set(COOKIES.KIOSK_NAME, data, { ...setterConfig });
    } else {
      Cookies.remove(COOKIES.KIOSK_NAME, { path: "/" });
    }
  },
  getKioskName: () => {
    return Cookies.get(COOKIES.KIOSK_NAME);
  },
  getKioskId: () => {
    return Cookies.get(COOKIES.KIOSK_ID);
  },
  setKioskId: (id) => {
    if (id) {
      Cookies.set(COOKIES.KIOSK_ID, id, { ...setterConfig, expires: 30 });
    } else {
      Cookies.remove(COOKIES.KIOSK_ID, { path: "/" });
    }
  },
  getRevocationEndpoint: () => {
    return Cookies.get(COOKIES.REVOCATION_ENDPOINT);
  },
  setRevocationEndpoint: (endpoint) => {
    Cookies.set(COOKIES.REVOCATION_ENDPOINT, endpoint, setterConfig);
  },
  getUserInfoEndpoint: () => {
    return Cookies.get(COOKIES.USERINFO_ENDPOINT);
  },
  setUserInfoEndpoint: (endpoint) => {
    Cookies.set(COOKIES.USERINFO_ENDPOINT, endpoint, setterConfig);
  },
  getUsers: () => {
    return Cookies.get(COOKIES.USERS);
  },
  getSSOIdpUrl: () => {
    return Cookies.get(COOKIES.SSO_IDP_URL);
  },
  setSSOIdpUrl: (ssoIdpUrl) => {
    Cookies.set(COOKIES.SSO_IDP_URL, ssoIdpUrl, setterConfig);
  },
  getSSOIdpClient: () => {
    return Cookies.get(COOKIES.SSO_IDP_CLIENT);
  },
  setSSOIdpClient: (ssoIdpClient) => {
    Cookies.set(COOKIES.SSO_IDP_CLIENT, ssoIdpClient, setterConfig);
  },
  setUsers: (users) => {
    Cookies.set(COOKIES.USERS, users, setterConfig);
  },
  setAccessToken: (accessToken) => {
    Cookies.set(COOKIES.ACCESS_TOKEN, accessToken, setterConfig);
  },
  setRefreshToken: (refreshToken) => {
    Cookies.set(COOKIES.REFRESH_TOKEN, refreshToken, setterConfig);
  },
  getLocus: () => {
    let locus = Cookies.get(COOKIES.LOCUS);
    return locus === "true" ? true : false;
  },
  setLocus: (locus) => {
    Cookies.set(COOKIES.LOCUS, locus, setterConfig);
  },
  getViewState: () => {
    return Cookies.get(COOKIES.VIEW_STATE);
  },
  setViewState: (viewState) => {
    Cookies.set(COOKIES.VIEW_STATE, viewState, setterConfig);
  },
  getSSOUser: () => {
    return Cookies.get(COOKIES.SSO_USER);
  },
  setSelectedDashboard: (selectedDashboard) => {
    Cookies.set(COOKIES.SELECTED_DASHBOARD, selectedDashboard, setterConfig);
  },
  getSelectedDashboard: () => {
    return Cookies.get(COOKIES.SELECTED_DASHBOARD);
  },
  setSSOUser: () => {},
  getLocusConfig: () => {
    return Cookies.get(COOKIES.LOCUS_CONFIG);
  },
  setLocusConfig: (config) => {
    Cookies.set(COOKIES.LOCUS_CONFIG, config, setterConfig);
  },
  getLocusAppVersion: () => {
    return Cookies.get(COOKIES.LOCUS_APP_VERSION) || "0.0.0";
  },
  setLocusAppVersion: (version) => {
    Cookies.set(COOKIES.LOCUS_APP_VERSION, version, setterConfig);
  },
  /**
   * @function removeAll
   * @description
   * Attempts to remove all cookies set by storage provider
   * Cookie names added as a property to `COOKIES` will be removed
   * Cookie names added to `PERSISTENT_COOKIES` will not be removed
   */
  removeAll: () => {
    // eslint-disable-next-line array-callback-return
    Object.keys(COOKIES).map((name) => {
      if (PERSISTENT_COOKIES.find((x) => x === COOKIES[name])) {
        // eslint-disable-next-line array-callback-return
        return;
      }
      let _cookie = Cookies.get(COOKIES[name]);
      if (!!_cookie) {
        Cookies.remove(COOKIES[name], { path: "/" });
      }
    });
  },
};
