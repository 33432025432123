import { useTheme } from "@mui/material/styles";
import { Avatar, Box, useMediaQuery, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import { useAuth } from "hooks/contexts/AuthContext";
import { LogoSection } from "../../LogoSection";
import ProfileSection from "./ProfileSection";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";

import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";
import { setViewState } from "store/slices/user";
import { setSelectedSite } from "../../../store/slices/customer";
import { toggleKioskQueryParam } from "../../../utils/kiosk-utils";
import { PublicOutlined } from "@mui/icons-material";

import { IconMenu2 } from "@tabler/icons-react";
import { IconChevronRight } from "@tabler/icons-react";
import navigation from "menu-items";
import DesktopAccessDisabledIcon from "@mui/icons-material/DesktopAccessDisabled";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { getHelpUrl } from "../../../utils/constants/help-urls";
import { storage } from "../../../utils/helpers/storage";

export const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { getClient } = useAuth();

  const dispatch = useDispatch();
  const { drawerOpen, kioskMode } = useSelector((state) => state.menu);

  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { layout } = useConfig();
  const { selectedDashboard } = useSelector((state) => state.analytics);
  const { locus, activeUser } = useSelector((state) => state.user);

  const openHelpWindow = () => {
    window.open(
      getHelpUrl("", selectedDashboard?.name),
      "_blank",
      "noopener,noreferrer",
    );
  };

  const handleListItemClick = async (event, index, route = "") => {
    if (route && route !== "") {
      storage.setDomain("locus");
      await getClient();
      await dispatch(setSelectedSite(null));
      await dispatch(setViewState("locus"));
      await navigate(route);
    }
  };

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>

        {(layout === LAYOUT_CONST.VERTICAL_LAYOUT && !kioskMode) ||
        (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT &&
          matchDownMd &&
          activeUser?.groups?.length &&
          !kioskMode) ? (
          <Avatar
            className="guided-tour-menu"
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              overflow: "hidden",
              transition: "all .2s ease-in-out",
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.background.paper
                  : theme.palette.secondary.light,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              "&:hover": {
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.primary.main
                    : theme.palette.secondary.light,
              },
            }}
            onClick={() => dispatch(openDrawer(!drawerOpen))}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="20px" />
          </Avatar>
        ) : null}
      </Box>

      {/* header search */}
      {/* {!kioskMode && <SearchSection />} */}
      {kioskMode && !!activeUser?.groups?.length && (
        <Box
          pl={2}
          sx={{
            width: "100%",
            display: "flex",
            flexGrow: 1,
            [theme.breakpoints.down("md")]: {
              width: "auto",
            },
          }}
        >
          <Breadcrumbs
            separator={IconChevronRight}
            navigation={navigation}
            icon
          />
          <Box sx={{ border: "none", pr: 1 }} style={{ marginLeft: "auto" }}>
            <IconButton
              color="primary"
              onClick={() => toggleKioskQueryParam(false, navigate)}
            >
              <DesktopAccessDisabledIcon />
            </IconButton>
            <IconButton color="primary" onClick={() => openHelpWindow()}>
              <HelpOutlineOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* mega-menu */}
      {/*<Box sx={{ display: { xs: 'none', sm: 'block' } }}>*/}
      {/*    <MegaMenuSection />*/}
      {/*</Box>*/}

      {/* live customization & localization */}
      {/*<Box sx={{ display: { xs: 'none', sm: 'block' } }}>*/}
      {/*    <LocalizationSection />*/}
      {/*</Box>*/}

      {/* notification & profile */}
      {/*<NotificationSection /> */}
      {locus && !!activeUser?.groups?.length && (
        <IconButton
          color="primary"
          onClick={(event) => handleListItemClick(event, 1, "/locus/clients")}
          sx={{ marginRight: "16px" }}
        >
          <PublicOutlined
            stroke={1.5}
            size="24px"
            color={theme.palette.primary.main}
          />
        </IconButton>
      )}
      <ProfileSection />

      {/* mobile header */}
      {/*<Box sx={{ display: { xs: 'block', sm: 'none' } }}>*/}
      {/*    <MobileSection />*/}
      {/*</Box>*/}
    </>
  );
};
